// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as LabelScss from "./Label.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = LabelScss;

function Label(props) {
  var __isRequired = props.isRequired;
  var __className = props.className;
  var status = props.status;
  var className = __className !== undefined ? __className : "";
  var isRequired = __isRequired !== undefined ? __isRequired : false;
  var tmp;
  tmp = status !== undefined && status.TAG !== "Ok" ? css.error : "";
  return JsxRuntime.jsx("label", {
              children: props.children,
              className: Cx.cx([
                    css.label,
                    tmp,
                    isRequired ? css.required : "",
                    className
                  ]),
              htmlFor: props.forId
            });
}

var make = Label;

export {
  css ,
  make ,
}
/* css Not a pure module */
