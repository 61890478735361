// extracted by mini-css-extract-plugin
export var column = "ProviderBrochuresIndex__column__X50Qo";
export var container = "ProviderBrochuresIndex__container__l4BZZ";
export var contentContainer = "ProviderBrochuresIndex__contentContainer__MRXIS";
export var paginationBar = "ProviderBrochuresIndex__paginationBar__h5vz4";
export var paginationInfo = "ProviderBrochuresIndex__paginationInfo__vVQVa";
export var popoverBody = "ProviderBrochuresIndex__popoverBody__NfEsb";
export var popoverLabel = "ProviderBrochuresIndex__popoverLabel__nqbxN";
export var popoverRow = "ProviderBrochuresIndex__popoverRow__NMMZq";
export var popoverTrigger = "ProviderBrochuresIndex__popoverTrigger__RdbFl";
export var providerBrochuresContainer = "ProviderBrochuresIndex__providerBrochuresContainer__CtzeE";
export var row = "ProviderBrochuresIndex__row__BpyJy";
export var sidebarContainer = "ProviderBrochuresIndex__sidebarContainer__B68YO";
export var sortSelection = "ProviderBrochuresIndex__sortSelection__b7wUW";