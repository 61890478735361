// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSelect from "../../../styleguide/icons/IconSelect.res.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderBrochure from "../../../api/provider-brochures/Api_ProviderBrochure.res.js";
import * as ProviderBrochuresIndexHeader from "./components/header/ProviderBrochuresIndexHeader.res.js";
import * as ProviderBrochuresIndexScss from "./ProviderBrochuresIndex.scss";
import * as ProviderBrochuresIndexSidebar from "./components/sidebar/ProviderBrochuresIndexSidebar.res.js";
import * as ProviderBrochuresIndexBrochure from "./components/brochure/ProviderBrochuresIndexBrochure.res.js";

var css = ProviderBrochuresIndexScss;

function ProviderBrochuresIndex$default(props) {
  var index = props.index;
  var initialState = React.useMemo((function () {
          return {
                  status: {
                    TAG: "Ready",
                    _0: index
                  },
                  currentPage: 1,
                  totalPages: 1,
                  sortBy: undefined,
                  search: "",
                  totalProviderBrochures: index.totalProviderBrochures,
                  currentTotalProviderBrochures: index.totalProviderBrochures,
                  providerIds: []
                };
        }), []);
  var container = React.useRef(null);
  var searchProviderBrochures = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchProviderBrochures" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              $$Promise.wait(Api_ProviderBrochure.index(state.currentPage, state.sortBy, state.search, state.providerIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedProviderBrochuresFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderBrochuresIndex",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ProviderBrochuresIndex.default"
                                          }, "ProviderBrochuresIndex::FetchProviderBrochures::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProviderBrochuresFetch");
                                    }));
                            })
                        };
              case "FailProviderBrochuresFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedProviderBrochuresFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            totalProviderBrochures: state.totalProviderBrochures,
                            currentTotalProviderBrochures: state.currentTotalProviderBrochures,
                            providerIds: state.providerIds
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: "",
                            totalProviderBrochures: state.totalProviderBrochures,
                            currentTotalProviderBrochures: state.currentTotalProviderBrochures,
                            providerIds: []
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviderBrochures");
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedProviderBrochuresFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            totalProviderBrochures: state.totalProviderBrochures,
                            currentTotalProviderBrochures: res.totalProviderBrochures,
                            providerIds: state.providerIds
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingProviderBrochures") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProviderBrochures",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              totalProviderBrochures: state.totalProviderBrochures,
                              currentTotalProviderBrochures: state.currentTotalProviderBrochures,
                              providerIds: state.providerIds
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviderBrochures");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingProviderBrochures") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProviderBrochures",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: action._0,
                            search: state.search,
                            totalProviderBrochures: state.totalProviderBrochures,
                            currentTotalProviderBrochures: state.currentTotalProviderBrochures,
                            providerIds: state.providerIds
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviderBrochures");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            totalProviderBrochures: state.totalProviderBrochures,
                            currentTotalProviderBrochures: state.currentTotalProviderBrochures,
                            providerIds: state.providerIds
                          },
                          _1: searchProviderBrochures
                        };
              case "PerformSearch" :
                  var match$2 = state.status;
                  if (typeof match$2 !== "object" && match$2 === "FetchingProviderBrochures") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProviderBrochures",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              totalProviderBrochures: state.totalProviderBrochures,
                              currentTotalProviderBrochures: state.currentTotalProviderBrochures,
                              providerIds: state.providerIds
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviderBrochures");
                              })
                          };
                  }
              case "UpdateProviderIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            totalProviderBrochures: state.totalProviderBrochures,
                            currentTotalProviderBrochures: state.currentTotalProviderBrochures,
                            providerIds: action._0
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviderBrochures");
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var x = state.sortBy;
  var res = state.status;
  var tmp;
  if (typeof res !== "object") {
    tmp = res === "FetchingProviderBrochures" ? JsxRuntime.jsx("div", {
            children: JsxRuntime.jsx(Spinner.make, {
                  size: "XL",
                  color: "Teal"
                }),
            className: css.providerBrochuresList
          }) : JsxRuntime.jsx("div", {
            children: "Something went wrong. Please try again later."
          });
  } else {
    var res$1 = res._0;
    var match$1 = state.status;
    var tmp$1;
    if (typeof match$1 !== "object") {
      tmp$1 = null;
    } else if (res$1.totalPages < 2) {
      var match$2 = res$1.totalProviderBrochures;
      tmp$1 = match$2 !== 0 ? (
          match$2 !== 1 ? "Showing all" : "Showing 1"
        ) : (
          state.search === "" ? JsxRuntime.jsx("p", {
                  children: "No data center brochures were found."
                }) : JsxRuntime.jsx("p", {
                  children: "No data center brochures were found with that search query. Please try a different search."
                })
        );
    } else {
      tmp$1 = "Showing " + (String(res$1.offset + 1 | 0) + (" to " + (String(res$1.offset + res$1.providerBrochures.length | 0) + (" of " + String(res$1.totalProviderBrochures)))));
    }
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: Belt_Array.mapWithIndex(res$1.providerBrochures, (function (index, show) {
                          return JsxRuntime.jsx(ProviderBrochuresIndexBrochure.make, {
                                      show: show
                                    }, String(index));
                        })),
                  className: css.providerBrochuresList
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    res$1.totalPages > 1 ? JsxRuntime.jsx(Pagination.make, {
                            currentPage: res$1.currentPage,
                            totalPages: res$1.totalPages,
                            onPageClick: (function (pageNum) {
                                dispatch({
                                      TAG: "UpdatePage",
                                      _0: pageNum
                                    });
                              })
                          }) : null,
                    JsxRuntime.jsx("div", {
                          children: tmp$1,
                          className: css.paginationInfo
                        })
                  ],
                  className: css.paginationBar
                })
          ]
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ProviderBrochuresIndexHeader.make, {
                      title: "Provider Brochures",
                      providerBrochuresCount: state.currentTotalProviderBrochures,
                      updateSearch: (function (search) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateSearchInput",
                                  _0: search
                                });
                          };
                        }),
                      updateProviderIds: (function (ids) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateProviderIds",
                                  _0: ids
                                });
                          };
                        }),
                      reset: (function () {
                          dispatch("Reset");
                        })
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(ProviderBrochuresIndexSidebar.make, {
                                    popularBrochures: index.popularBrochures,
                                    recentBrochures: index.recentBrochures
                                  }),
                              className: css.sidebarContainer
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs(Popover.make, {
                                            children: [
                                              JsxRuntime.jsxs(Popover.Trigger.make, {
                                                    className: css.popoverTrigger,
                                                    children: [
                                                      JsxRuntime.jsx(Control.AsLink.make, {
                                                            color: "Gray",
                                                            className: css.popoverLabel,
                                                            onClick: (function (param) {
                                                                
                                                              }),
                                                            children: x !== undefined ? (
                                                                x.NAME === "Title" ? (
                                                                    x.VAL === "Desc" ? "Sort by Title, Z to A" : "Sort by Title, A to Z"
                                                                  ) : (
                                                                    x.VAL === "Desc" ? "Sort by Provider, Z to A" : "Sort by Provider, A to Z"
                                                                  )
                                                              ) : "Choose Sort Criteria"
                                                          }),
                                                      JsxRuntime.jsx(IconSelect.make, {
                                                            size: "XS",
                                                            color: "GrayText",
                                                            className: css.iconSelect
                                                          })
                                                    ]
                                                  }),
                                              JsxRuntime.jsxs(Popover.Body.make, {
                                                    position: {
                                                      TAG: "Below",
                                                      _0: "LeftEdge"
                                                    },
                                                    className: css.popoverBody,
                                                    children: [
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: "Title, A-Z",
                                                                  className: css.sortSelection,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "SortBy",
                                                                            _0: {
                                                                              NAME: "Title",
                                                                              VAL: "Asc"
                                                                            }
                                                                          });
                                                                    })
                                                                }),
                                                            className: css.popoverRow
                                                          }),
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: "Title, Z-A",
                                                                  className: css.sortSelection,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "SortBy",
                                                                            _0: {
                                                                              NAME: "Title",
                                                                              VAL: "Desc"
                                                                            }
                                                                          });
                                                                    })
                                                                }),
                                                            className: css.popoverRow
                                                          }),
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: "Provider, A-Z",
                                                                  className: css.sortSelection,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "SortBy",
                                                                            _0: {
                                                                              NAME: "Provider",
                                                                              VAL: "Asc"
                                                                            }
                                                                          });
                                                                    })
                                                                }),
                                                            className: css.popoverRow
                                                          }),
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: "Provider, Z-A",
                                                                  className: css.sortSelection,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "SortBy",
                                                                            _0: {
                                                                              NAME: "Provider",
                                                                              VAL: "Desc"
                                                                            }
                                                                          });
                                                                    })
                                                                }),
                                                            className: css.popoverRow
                                                          })
                                                    ]
                                                  })
                                            ]
                                          }),
                                      className: css.brochuresSort
                                    }),
                                tmp
                              ],
                              className: css.providerBrochuresContainer
                            })
                      ],
                      className: css.contentContainer
                    })
              ],
              className: css.container
            });
}

var $$default = ProviderBrochuresIndex$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
