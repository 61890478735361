// extracted by mini-css-extract-plugin
export var column = "ProviderBrochuresIndexBrochure__column__pvNzi";
export var downloadContainer = "ProviderBrochuresIndexBrochure__downloadContainer__b89dS";
export var image = "ProviderBrochuresIndexBrochure__image__F2XDG";
export var imageContainer = "ProviderBrochuresIndexBrochure__imageContainer__I1j65";
export var linkContainer = "ProviderBrochuresIndexBrochure__linkContainer__M8YY7";
export var provider = "ProviderBrochuresIndexBrochure__provider__kVNXu";
export var providerBrochure = "ProviderBrochuresIndexBrochure__providerBrochure__Yie4c";
export var row = "ProviderBrochuresIndexBrochure__row__bVJLB";
export var subtitle = "ProviderBrochuresIndexBrochure__subtitle__OkDSz";
export var title = "ProviderBrochuresIndexBrochure__title__PfxPj";
export var titleContainer = "ProviderBrochuresIndexBrochure__titleContainer__hJRpF";